window.jQuery = require('jquery');
// window.Tether = require('tether');
window.Bootstrap = require('bootstrap');
window.Owlcarousel = require('owl.carousel');
window.MatchHeight = require('jquery-match-height');
window.MatchHeight = require('jquery.mmenu');

(function(d, s, id) {
  var js, fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) return;
  js = d.createElement(s); js.id = id;
  js.src = 'https://connect.facebook.net/zh_TW/sdk.js#xfbml=1&version=v3.0';
  fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));

var $nav = jQuery("nav.navbar");

jQuery(function(){
  jQuery('.nav a').filter(function(){return this.href==location.href}).parent().addClass('active').siblings().removeClass('active')
  jQuery('.nav a').click(function(){
    jQuery(this).parent().addClass('active').siblings().removeClass('active')  
  })
});

jQuery(function(){
  jQuery('#menu a').filter(function(){return this.href==location.href}).parent().addClass('active').siblings().removeClass('active')
  jQuery('#menu a').click(function(){
    jQuery(this).parent().addClass('active').siblings().removeClass('active')  
  })
});

var headerHeight = jQuery(".main-slider").outerHeight();

jQuery(window).scroll(function () {
  console.log("scrollTop: " + jQuery(window).scrollTop());
  console.log("headerHeight: " + headerHeight);

  if (jQuery(window).scrollTop() > headerHeight) {
    $nav.addClass('navbar-fixed-top');
    jQuery(".sticky-social").show();
  } else {
    $nav.removeClass('navbar-fixed-top');
  }
});

jQuery(document).on('click', '.anchor-link', function (event) {
    event.preventDefault();

    jQuery('html, body').animate({
        scrollTop: jQuery(jQuery.attr(this, 'href')).offset().top - 300
    }, 500);
});

// page loader
jQuery(window).load(function() {
	jQuery(".se-pre-con").fadeOut("slow");;
});

// back to top
jQuery(document).ready(function(){
	jQuery(window).scroll(function(){
		if (jQuery(this).scrollTop() > 100) {
			jQuery('#totop').fadeIn();
		} else {
			jQuery('#totop').fadeOut();
		}
	});
	jQuery('#totop').click(function(){
		jQuery('html, body').animate({scrollTop : 0},800);
		return false;
	});
});

jQuery(document).ready(function() {
  // home slider desktop
  jQuery("#home-slider").owlCarousel({
  	margin : 0,
		autoplay : true,
    autoplayTimeout : 4000,
    autoplayHoverPause: true,
    loop: true,
    video: true,
    nav: true, 
    // animateOut: 'fadeOut',
    // animateIn: 'fadeIn',
		// navText: [
    //   "<img src='images/prev.png'>",
    //   "<img src='images/next.png'>"
    // ],
    slideSpeed : 1000,
    paginationSpeed : 4000,
    items: 1,
    // autoHeight:true,
    lazyLoad: true
    // singleItem: true,
  });
  jQuery(".owl-prev").html('<i class="fa fa-chevron-left"></i>');
  jQuery(".owl-next").html('<i class="fa fa-chevron-right"></i>');
  // owl = jQuery('#home-slider').owlCarousel();
	// jQuery(".prev").click(function () {
	//     owl.trigger('prev.owl.carousel');
	// });

	// jQuery(".next").click(function () {
	//     owl.trigger('next.owl.carousel');
	// });

  // home slider mobile
  // jQuery("#mobile-slider").owlCarousel({
  //   margin : 0,
  //   autoplay : true,
  //   autoplayTimeout : 4000,
  //   loop : true,
  //   nav : true,
  //   navText: [
  //     "<i class='fa fa-angle-left'></i>",
  //     "<i class='fa fa-angle-right'></i>"
  //   ],
  //   dots : false,
  //   slideSpeed : 1000,
  //   paginationSpeed : 4000,
  //   items: 1,
  //   autoHeight:true
  // });

});

// var $mmenu = jQuery("#menu");

jQuery(function() {
	jQuery('#menu').mmenu();
});

jQuery(function() {
	jQuery(".equalheight").matchHeight({
	  byRow: true,
	  property: 'height',
	  target: null,
	  remove: false
	});
});

jQuery('img.svg').each(function(){
  var $img = jQuery(this);
  var imgID = $img.attr('id');
  var imgClass = $img.attr('class');
  var imgURL = $img.attr('src');

  jQuery.get(imgURL, function(data) {
      // Get the SVG tag, ignore the rest
      var $svg = jQuery(data).find('svg');

      // Add replaced image's ID to the new SVG
      if(typeof imgID !== 'undefined') {
          $svg = $svg.attr('id', imgID);
      }
      // Add replaced image's classes to the new SVG
      if(typeof imgClass !== 'undefined') {
          $svg = $svg.attr('class', imgClass+' replaced-svg');
      }

      // Remove any invalid XML tags as per http://validator.w3.org
      $svg = $svg.removeAttr('xmlns:a');

      // Replace image with new SVG
      $img.replaceWith($svg);

  }, 'xml');
});

